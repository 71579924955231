.Card {
  margin: 15px 0px 15px 0px;
  transition: border .1s;
  height: 300px;
  border-radius: 12px;
  z-index: 1;
  position: relative;
  cursor: grab;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.Hover {
  border: 1px solid lightskyblue !important;
  border-radius: 14px !important;
  cursor: grab !important;
  animation: hover-animation 0.3s forwards;
  scale: 1.3;
  z-index: 2;
}


.CardOverlay {
  animation: opacity-animation 0.4s forwards;
}



/* Battle Cards are read horizontally, so mouseovering it rotates it to display properly (battle-hover-animation). As it's the same for Fuse Cards, they're being assumed as Battle class. */

.Battle {
  margin: 15px 0px 15px 0px;
  transition: border 0.1s;
  height: 300px;
  border-radius: 11px;
  z-index: 1;
}

.Battle:hover {
  border: solid 8px lightskyblue;
  border-radius: 25px;
  border-left: solid 42px lightskyblue;
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  animation: battle-hover-animation 0.4s forwards;
  cursor: grab;
  z-index: 4;
}

/* Plane Cards are read horizontally, but unlike Battles they are already wide-long by default. To maintain pattern, it's getting rotated before hover and on mouseover it turns horizontal.  */
.Plane {
  margin: 15px 0px 15px 0px;
  transition: border 0.1s;
  height: 300px;
  border-radius: 14px;
  z-index: 1;
}

.Plane:hover {
  border: solid 8px lightskyblue;
  border-radius: 25px;
  border-left: solid 42px lightskyblue;
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  animation: battle-hover-animation 0.4s forwards;
  cursor: grab;
  z-index: 4;
}

.Collected {
  margin: 15px 0px 15px 0px;
  transition: border 0.1s;
  border-radius: 12px;
  z-index: 1;
  position: relative;
  height: 250px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Collected:hover{
  border: 1px solid lightskyblue;
  border-radius: 14px;
  cursor: grab;
}

.Collected:hover ~ .CardOverlay {
  animation: opacity-animation 0.4s forwards;
}

.CardOverlay {
  position: absolute;
  top: -14px;
  left: 40px;
  height: 16px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  z-index: 5;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.CardContainer {
  position: relative;
  margin: auto;
  width: 243px;
}

.CollectionCardContainer {
  position: relative;
  margin: auto;
  width: 243px;
}

/* Scaled Card Classes */

.Disabled {
  display: none !important;
  position: absolute;
  right: -215px;
  top: 100px;
  scale: 0.45;
  z-index: 10;
}

.Default {
  margin: 15px 0px 15px 0px;
  transition: border .1s;
  border-radius: 12px;
  z-index: 10;
  scale: 0.45;
  position: relative;
}

.LeftCentered {
  position: absolute;
  
  top: -300px;
  scale: 0.45;
  z-index: 10;
}

.RightCentered {
  position: absolute;
  right: 0px;
  top: -300px;
  scale: 0.45;
  z-index: 10;
}

.Left {
  position: absolute;
  left: 75px;
  top: -300px;
  scale: 0.45;
  z-index: 10;

}

.LeftPlaneOrBattle {
  position: absolute;
  left: 150px;
  top: -300px;
  scale: 0.45;
  z-index: 10;

}

.Right {
  position: absolute;
  right: 75px;
  top: -300px;
  scale: 0.45;
  z-index: 10;

}


.RightPlaneOrBattle {
  position: absolute;
  right: 150px;
  top: -300px;
  scale: 0.45;
  z-index: 10;

}

.scaledCard {
  border-radius: 30px;
  animation: hover-animation 0.3s forwards;
  z-index: 10;
}

.scaledPlaneOrBattle {
  border-radius: 30px;
  animation: battle-hover-animation 0.3s forwards;
  z-index: 10;
}

/* Animations */

/* Hover for cards */
@keyframes hover-animation {
  0% {
    transform: scale(1.11);
    opacity: 0;
  }

  50% {
    transform: scale(1.11);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
  }
}

/* Hover for Battle, Plane and Fuse cards*/
@keyframes battle-hover-animation {
  0% {
    transform: scale(1.11);
    opacity: 0;
  }

  50% {
    transform: scale(1.11);
    opacity: 1;
  }

  100% {
    transform: scale(1.2) rotate(90deg);
  }
}

/* Opacity for Card Overlay*/
@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Dragging */

.Dragging {
  cursor: grabbing;
  opacity: 1;
  border: solid black 500px;
}

/* addToDeck */

.addToDeckButton {
  z-index: 8;
}

.addToDeckButton:hover {
  border: solid 2px green;
}

.hiddenOverlay {
  visibility: hidden;
  
}

.showingOverlay {
  visibility: visible;
  margin: 0px 10px 0px 10px;
  padding: 0 20px 0 20px;
  font-size: 12px;
}

@media (max-width: 972px) {
  .showingOverlay {
    margin: 65px 10px 0px 10px;
  }
}

.Undraggable {
  opacity: .5 !important;
}