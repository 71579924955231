.deckContainer {
    display: flex;
    background-color: orangered;
    justify-content: center;
    margin: 30px auto 30px auto;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border: 0;
    min-width: 200px;
    max-width: 290px;
    height: 225px;
    align-items: flex-start;
    padding-top: 30px;
    position: relative;
    text-align: center;
    border-radius: 50px;
    transition: box-shadow, scale .2s;
    background: var(--deckBackground);
    box-shadow:  var(--deckBoxShadow);

}

.btnClose {

    background-color: transparent;
    font-size: 26px;
    position: absolute;
    cursor: pointer;
    border: 0;
    right: 8px;
    top: 12px;
    border-radius: 50%;
    transition: all .2s;
    padding-bottom: 5px;
    width: 43px;
    z-index: 99;
}

.btnClose:hover {
    opacity: 0.7;
    background-color: lightgrey;
}

.hide {
    display: none;
}

.edit {
    left: 8px !important;
    top: 12px !important;
    width: 43px;
}


.deck {
    
    height: 50%;
    width: 80%;
}

.deckContainer:hover {
    scale: 1.1;
    border: 4px solid rgb(105, 235, 66);
    background: var(--deckBackground);
    box-shadow:  var(--deckBoxShadowHover);
    cursor: pointer;

}