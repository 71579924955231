.ButtonsContainer {
    margin: 100px;
    height: 100px;
    align-items: center;
    background-color: transparent;
    font-size: 20px;
    justify-content: center;
    justify-items: center;
    display: flex;
    z-index: 10;
}

.Hidden {
    display: none;
}

.Button {
    border-radius: 8px;
    background-color: #FFC100;
    color: rgb(0, 0, 0);
    font-weight: bold;
    padding: 5px 7px 5px 7px;

    
}

.SideBar {
    margin: auto;
    z-index: 10;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: center;
    justify-items: center;

}

.Collection {
    margin: auto;
    width: 85px;
    font-size: 11px;
    z-index: 10;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: flex-end;
}