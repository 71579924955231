.MiniCard {
    display: flex;
    height: 24px;
    width: 249px;
    color: black;
    margin: 4px 0px 4px 5px;
    border-radius: 2% / 50%;
    font-size: 13px;
    font-family: "BelerenBold";
    justify-items: center;
    vertical-align: middle;

}

.MinierCard {
    position: relative;
    display: flex;
    height: 24px;
    width: 260px;
    color: black;
    margin: 5px 0px 5px 5px;
    font-size: 14px;
    font-family: "BelerenBold";
    justify-items: center;
    vertical-align: middle;
}



.Count {
    text-align: start;
    padding-left: 8px;
    padding-top: 3px;
    width: 55px;
    margin-right: -23px;
    background-color: black;
    color: white;
    background:
      radial-gradient(circle at right, transparent, transparent 44%, #000000 15%) right;
  background-size: 100% 100%;
  background-repeat:no-repeat;

}


.Main {
    align-items: center;
    padding-left: 3px;
    border: transparent 2px black;
    border-radius: 2% / 50%;
    width: 221px;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(145deg, #fdf4c6, #dfcc99);
    box-shadow:  2px 2px 1px #ae9f77,
    -1px -1px 2px #ffffdd;
  }

  .Main:hover {
    border: solid 2px rgb(80, 139, 184);
    border-radius: 2% / 50%;
    scale: 1.06;
    box-shadow:  -2px 3px 6px #000000,
    2px -2px 6px #BA5A31;
    z-index: 2;

}

.Mainier {
  align-items: center;
  padding-left: 3px;
  border: transparent 2px black;
  border-radius: 2% / 50%;
  width: 221px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(145deg, #fdf4c6, #dfcc99);
  box-shadow:  2px 2px 1px #ae9f77,
  -1px -1px 2px #ffffdd;
  animation: spawn .3s;
}

.Mainier:hover {
  border: solid 2px rgb(80, 139, 184);
  border-radius: 2% / 50%;
  scale: 1.06;
  box-shadow:  -6px 6px 8px #000000,
  2px -5px 8px #3d3d3d;
  z-index: 2;

}

/* Icons */

.colorlessIcon {
    margin: 0 1px 0 1px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-size: 10px;
    font-family: "BelerenBold";
    width: 12px;
    height: 12px;
    background-color:#b9b1a1;
    border-radius: 100%;
    box-shadow: rgb(0, 0, 0) -1px 1px 2px;
    
}

.cardCost {
    padding: 0 2px 0 2px;
}

.coloredIcon {
    margin: 0 1px 0 1px;
    border-radius: 100%;
    box-shadow: rgb(0, 0, 0) -1px 1px 2px;
}

/* Hovering */

.Card {
  margin: 15px 0px 15px 0px;
  transition: border .1s;
  border-radius: 12px;
  z-index: 3;
  position: relative;
}

.Centered {

  position: absolute;
  right: -232px;
  top: -170px;
  scale: 0.45;
  z-index: 10;
}

.Left {
    position: absolute;
    left: 35px;
    top: -400px;
    scale: 0.35;
    z-index: 10;
  }
  
  .LeftPlaneOrBattle {
    position: absolute;
    left: 75px;
    top: -350px;
    scale: 0.35;
    z-index: 10;
  }
  
  .Right {
    position: absolute;
    right: 35px;
    top: -400px;
    scale: 0.35;
    z-index: 10;
  }
  
  
  .RightPlaneOrBattle {
    position: absolute;
    right: 75px;
    top: -350px;
    scale: 0.35;
    z-index: 10;
  }
  
  .scaledCard {
    border-radius: 12px;
    animation: hover-animation 0.3s forwards;
    z-index: 10;
  }
  
  .scaledPlaneOrBattle {
    border-radius: 12px;
    animation: battle-hover-animation 0.3s forwards;
    z-index: 10;
  }

/* Animations */

/* Hover for cards */
@keyframes hover-animation {
    0% {
      transform: scale(1.11);
      opacity: 0;
    }
  
    50% {
      transform: scale(1.11);
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2);
    }
  }
  
  /* Hover for Battle, Plane and Fuse cards*/
  @keyframes battle-hover-animation {
    0% {
      transform: scale(1.11);
      opacity: 0;
    }
  
    50% {
      transform: scale(1.11) rotate(90deg);
      
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2) rotate(90deg);
    }
  }

  /* Minicard Added */
  @keyframes spawn {
    0% {
      color: white;
      background: white;
      transform: scale(0);
    }

    50% {
      color: white;
      background: white;
      transform: scale(1.2);
    }

    100% {
      color: rgb(0, 0, 0);
      background: linear-gradient(145deg, #fdf4c6, #dfcc99);
      transform: scale(1);
    }
  }
