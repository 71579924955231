.loginContainer {
    width: 400px;
    height: max-content;
    margin: auto;
    background-color: #bcbcbc;
    padding: 20px 50px;
    margin-top: 120px;
    margin-bottom: 120px;
    border-radius: 7px;
    font-family: sans-serif;
    box-shadow: 10px 11px rgba(0, 0, 0, 0.7);
}


.title {
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
    color: black;
}


.loginForm {
    padding: 5px;
}

.formField {
    display: inline-block;
    padding: 5px;
    margin-top: 20px;
}

.formError {
    display: block;
    color: red;
    font-size: 0.9em;
    font-weight: 600;
}

.button {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px 10px;

    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    width: 200px;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
}

.button:hover {
    color: white;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
    background-color: rgba(104, 85, 224, 1);
  }