.titleContainer {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  margin: 80px auto 40px auto;
  box-sizing: border-box;
  vertical-align: middle;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.title {
  max-width: 100%;
    min-width: 368px;
    
}

.chestContainer {
    /* Reset width for the second inner div to take full width */
    width: 200px;
    margin: 0px auto -100px auto;
display: flex;
}

.chestContent {
  position: relative;
}

.chestWrapper {
  position: relative;
}

.chest {
  position: relative;
  top: 0;
  left: auto;
  width: 100%; /* Adjust as needed */
  rotate: 0deg;

}

/* This is for the cards image on the logo, not the actual cards (components) */
.fCards {
  position: relative;
  left: auto;
  max-width: 80px;
  top: -175px;
rotate: 5deg;
overflow: hidden;
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
}

/* Float animation */
@keyframes float {
	0% {

		transform: translatey(0px);
	}
	50% {

		transform: translatey(-10px);
	}
	100% {

		transform: translatey(0px);
	}
}

.Paragraph {
  font-weight: bold;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.list {
  font-weight: 600;
  max-width: 900px;
  margin: auto;
  line-height: 25px;
  list-style: disc;
  text-align: start;
  margin-bottom: 40px;
  padding-right: 10px;
  text-align: justify;
  text-justify: inter-word;
}

.listItem {
  margin: 15px 0 15px 0;
}

.h1 {
  margin-bottom: 20px;
}

/* Modal switch */

.upperContainer {
  width: 100%;
  margin: auto;
  animation: hideSideBar .1s;
}

.upperContainerWithModal {
  padding: 0 10px 0 10px;
  width: 100%;
  animation: showSideBar .1s;
}

.cardsContainer {  
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    animation: hideSideBar .1s;
  
}

.cardsContainerWithModal {
  padding: 0 10px 0 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    animation: showSideBar .1s;

}

/*Modal Switch Animation */

@keyframes showSideBar {
  from {
      margin: auto;
  }
  to {
      margin: 0px;
  }
}

@keyframes hideSideBar {
  from {
    margin: 0px;
  }
  to {
    margin: auto;
  }
}

/* Modal Switch Responsiveness Details */

@media (min-width: 849px) {

    .upperContainer {
      width: 90%;
    }

    .upperContainerWithModal {
      width: 69%;
    }
    
    .cardsContainer {
        width: 90%;
    }

    .cardsContainerWithModal {
      width: 69%;
  }
    
  }

  @media (min-width: 992px) {

    .upperContainer {
      width: 70%;
    }

    .upperContainerWithModal {
      width: 70%;
    }

    .cardsContainer {
        width: 70%;
    }

    .cardsContainerWithModal {
        width: 70%;
    }
  }

  @media (min-width: 1200px) {

 

    .upperContainer {
      width: 80%;
    }

    .upperContainerWithModal {
      width: 75%;
    }


    .cardsContainer {
        width: 80%;
    }

    .cardsContainerWithModal {
        width: 75%;
    }
  }

.MTG {
  font-family: 'BelerenBold';
}