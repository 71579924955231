/* Dark mode */
:root {
  --bodyBackground: #f8f9fa;
  --bodyBackgroundActive: #e2e2ea;
  --textColor: #5A5353;
  --linkColor: cyan;
  --sideBar: rgb(240, 216, 143);
  --deckBackground: linear-gradient(145deg, #cacaca, #f0f0f0);
  --deckBoxShadow: 5px 5px 10px #bebebe,
  -5px -5px 10px #ffffff;
  --deckBoxShadowHover: 20px 20px 60px #bebebe,
  -20px -20px 60px #ffffff;
}

[data-theme="dark"] {
  --bodyBackground: #212529 ;
  --bodyBackgroundActive: #000000;
  --textColor: white;
  --linkColor: navy;
  --sideBar: rgb(240, 216, 143);
  --deckBackground: linear-gradient(145deg, #768392, #474f55);
  --deckBoxShadow: 5px 5px 10px #1c1f23,
  -5px -5px 10px #262b2f;
  --deckBoxShadowHover: 20px 20px 60px #1c1f23,
  -20px -20px 60px #262b2f;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* MTG font */
@font-face {
  font-family: "BelerenBold";
  src: local("BelerenBold"),
   url("./fonts/Beleren2016-Bold.ttf") format("truetype");
  font-weight: bold;
 }