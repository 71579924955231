
.Background {
    background-color: var(--bodyBackground);
    padding-top: 15px;
}

.title {
    font-size: 50px;
}

.cardsContainer {
    margin: auto;
    height: 530px;
    width: 96%;
    align-items: flex-start;
    display: flex;
}

.cardsRow {
    margin: 100px 0 50px 0;
}

/* Select */

.deckContainer { 
    animation: bg-color-change .1s forwards;
    background-color: rgb(61, 61, 61);
    height: 100%;
    margin: 20px auto auto auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 180px;
}


.UponDraggedItem {
    background-color: rgb(78, 78, 78);
    height: 100%;
    margin: 20px auto auto auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 180px;
    opacity: 1;
    z-index: 2;
}

/* Great debugger I came up with for the drag and drop blinking issue: whenever dragging element over the droppable zone, if you pass thorugh any inside div borders, it would not be 'dragging over' for a glimpse of time, resulting in odd blinks caused by fast class changing. But with this animation, it won't blink because for 0.9s it won't change the bg-color and that's more than the time of the cursor passing through div border. */
@keyframes bg-color-change {
    0% {
        background-color: rgb(78, 78, 78);
    }
  
    90% {
        background-color: rgb(78, 78, 78);
    }
  
    100% {
        background-color: rgb(61, 61, 61);
    }
  }

.selectDeck {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 5px 0 5px 0;
}

.newDeckButton {
    right: 0;
    border-radius: 8px;
    padding: 6px;
    font-family: 'BelerenBold';
    font-size: 20px;
    box-shadow: 2px 2px black;
    background-color: rgb(174, 242, 174);
}

.newDeckButton:hover {
    background-color: rgb(43, 184, 43);
    
}

.newDeckButton:active {
    background-color: rgb(43, 184, 43);
    box-shadow: inset 1px 1px black;
}

.selectInput {
    text-align: center;
    font-size: 23px;
    font-family: "BelerenBold";
    width: 250px;
    height: 45px;
    border-radius: 20px;
}

/* minicards */

.minicardsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 18px;
    height: 100%;
    min-height: 293px;
    margin: auto;
}

.minicardsCol {
    min-width: 200px;
    min-height: 91px;
    margin: 0;
}

@media (max-width: 567px) {
    .minicardsCol {
      margin: auto;
    }

    .selectDeck {
        justify-content: space-around;

      }
}

.even {
    color: white !important;
    width: 95px;
    flex-shrink: 0;
}

.odd {
    flex-shrink: 1;
}

.Red { 
    color: rgb(255, 49, 49);
    font-weight: bold;
}

.Normal {
    font-weight: bold;

}

.DeckColor {
    font-weight: bold;
}

.DeckColor:hover + .hide {
    display: block;
}

.hide {
    display: none;
    position: absolute;
    z-index: 10;
    width: 200px;
    height: 100px;
    margin-top: 18px;
    margin-left: 7px;

}