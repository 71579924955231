* {
    margin: 0px;
    padding: 0px;
}

.Container {
    color: var(--textColor);
    background-size: auto;
    background-color:var(--bodyBackground);
    transition: background-color 0.9s ease;
    /* This inhibits the Container to be pushed down in case of the first element of the page being top-marged*/
    float: left;
    width: 100%;
    margin-top: 60px;

}

.Container.active {
    color: var(--textColor);
    background-color: var(--bodyBackgroundActive);
}

/* 
:root {
  --bodyBackground: #ffffff;
  --bodyBackgroundActive: tomato;
  --textColor: rgb(0, 0, 0);
  --linkColor: cyan;
  --sideBar: rgb(57, 22, 0);
}

[data-theme="dark"] {
  --bodyBackground: #000000;
  --bodyBackgroundActive: #0a0903;
  --textColor: white;
  --linkColor: navy;
  --sideBar: rgb(157, 69, 15);
}

*/
