.Footer {
  float: left;
  position:initial;
  left: 0;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: center;

  bottom: 0;
  width: 100%;

  background-color: #000000;
  color: #FF8200;
  text-align: center;
  height: 300px;
}
