.ClosedToggler {
    position: fixed;
    cursor: pointer;
    top: 50%;
    right: 0;
    z-index: 3;
    animation: closing .3s;
    rotate: 180deg;
    border: 2px solid transparent;
    
}

.OpenToggler {
    position: fixed;
    cursor: pointer;
    top: 50%;
    right: 252px;
    z-index: 3;
    animation: opening .3s;
    border: 2px solid transparent;

}

.ClosedToggler:hover{
    border: solid 2px red;
    border-radius: 100%;
}

.OpenToggler:hover {
    border: solid 2px red;
    border-radius: 100%;
}



@keyframes opening {
    from {
        right: 0;
    }
    to {
        right: 252px;
    }
}

@keyframes closing {
    from {
        right: 252px;
        
    }
    to {
        right: 0;
    }
}

