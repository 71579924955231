.image1 {
    height: 300px;
    border-radius: 0px 30px 0px 30px;
}


.text {
    margin-top: 50px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: justify;
    text-justify: inter-word;
}

.title {
    margin-bottom: 20px;
    font-size: 70px;
    font-family: 'Roboto';
    color: rgb(240, 216, 143);
    text-shadow: 1px 1px black;
}

.container {
    margin: auto;
    width: 80%;

}